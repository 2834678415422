import { App } from '@/app.models';
import { MODULES } from '@/constants';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
    {
        path: '/',
        name: MODULES.HOMEPAGE.ROUTE.NAME.HOME,
        component: () => import('@/modules/homepage/views/HomepageView.vue'),
        meta: {
            anchor: 'Home',
            icon: 'mdi-home',
        } as App.RouteMetadata,
    },
];

export default routes;
